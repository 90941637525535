.create-poll-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 4rem 1fr;
  grid-auto-columns: 1fr;
  gap: 3em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "place place ."
    "poll-name poll-name ."
    "poll-button . .";
  margin-top: 5rem;
}

.poll-button {
  grid-area: poll-button;
}

.place {
  grid-area: place;
}

.poll-name {
  grid-area: poll-name;
}

.poll-name input {
  margin-left: 0.5rem;
}

.create-poll-button {
  padding: 10px;
  border-radius: 5px;
  min-width: 200px;
  font-size: 16px;
}

.create-poll-button:hover {
  font-weight: bold;
}
