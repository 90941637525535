.ceramics-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 60px 60px 60px 60px 60px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description description"
    "description description description description"
    "fragments fragments . ."
    "form form piece state"
    "category category typology cooking"
    "cronology cronology century date"
    "painted painted decoration decoration"
    "exterior exterior interior interior"
    "coordinates x y z";
}

.description {
  grid-area: description;
}

.fragments {
  grid-area: fragments;
}

.piece {
  grid-area: piece;
}

.form {
  grid-area: form;
}

.state {
  grid-area: state;
}

.category {
  grid-area: category;
}

.typology {
  grid-area: typology;
}

.cooking {
  grid-area: cooking;
}

.cronology {
  grid-area: cronology;
}

.century {
  grid-area: century;
}

.date {
  grid-area: date;
}

.painted {
  grid-area: painted;
}

.decoration {
  grid-area: decoration;
}

.exterior {
  grid-area: exterior;
}

.interior {
  grid-area: interior;
}

.coordinates {
  grid-area: coordinates;
}

.x {
  grid-area: x;
}

.y {
  grid-area: y;
}

.z {
  grid-area: z;
}
