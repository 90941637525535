.glass-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 60px 60px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description description"
    "description description description description"
    "fragments fragments fragments ."
    "form form function state"
    "cronology cronology century date"
    "coordinates x y z";
}

.description {
  grid-area: description;
}

.fragments {
  grid-area: fragments;
}

.form {
  grid-area: form;
}

.function {
  grid-area: function;
}

.state {
  grid-area: state;
}

.cronology {
  grid-area: cronology;
}

.century {
  grid-area: century;
}

.date {
  grid-area: date;
}

.coordinates {
  grid-area: coordinates;
}

.x {
  grid-area: x;
}

.y {
  grid-area: y;
}

.z {
  grid-area: z;
}
