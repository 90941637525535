h3 {
  font-size: 16px;
}

.ue-title {
  text-align: center;
  color: #b7410e;
}

.ue-grid-container {
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 1fr;
  grid-template-rows: 50px 60px;
  gap: 1em 1em;
  grid-template-areas:
    "place place acronym acronym"
    "caracterization caracterization . .";
}

.place {
  grid-area: place;
}

.acronym {
  grid-area: acronym;
}
.ue {
  grid-area: ue;
}
.coordinates {
  grid-area: coordinates;
}
.latitude {
  grid-area: latitude;
}
.longitude {
  grid-area: longitude;
}

.caracterization {
  grid-area: caracterization;
}

.border {
  border: 1px solid black;
  padding: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.ue-input {
  outline: none;
  /* width: 300px; */
}

.ue-h3 {
  margin-top: auto;
  margin-bottom: auto;
}

hr {
  border: 2px solid black;
}

.searchWrapper {
  max-height: 22px;
}

.multiSelectContainer {
  margin-left: 5px;
}

.create-ue-btn {
  padding: 10px;
  border-radius: 5px;
  min-width: 100px;
  font-size: 16px;
  margin-top: 3rem;
}

.create-ue-btn:hover {
  font-weight: bold;
}
