.filter-input-wrapper {
  position: relative;
  margin-bottom: 20px;
  margin-top: 30px;
}

.filter-input-wrapper input {
  padding: 10px;
  text-indent: 30px;
}

.filter-input-input {
  min-width: 300px;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: calc(75% - 1.1em);
}
