.sample-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 60px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description description"
    "description description description description"
    "sample sample . ."
    "cronology cronology century century"
    "coordinates x y z";
}

.description {
  grid-area: description;
}

.sample {
  grid-area: sample;
}

.cronology {
  grid-area: cronology;
}

.century {
  grid-area: century;
}

.coordinates {
  grid-area: coordinates;
}

.x {
  grid-area: x;
}

.y {
  grid-area: y;
}

.z {
  grid-area: z;
}
