.interface-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100px 50px 50px 50px;
  gap: 1em 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description"
    "interpretation interpretation interpretation"
    "interpretation interpretation interpretation"
    "cronology cronology .";
}

.description {
  grid-area: description;
}

.interpretation {
  grid-area: interpretation;
}

.cronology {
  grid-area: cronology;
}

.border {
  border: 1px solid black;
}

.flex {
  display: flex;
  flex-direction: row;
}

.ue-input {
  border: none;
  /* margin-bottom: auto;
  margin-top: auto; */
}

.ue-input-data {
  border: none;
  margin-left: 10px;
  min-width: 600px;
  resize: none;
}
