.sedimentary-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 60px 50px;
  gap: 1em 1em;
  grid-template-areas:
    "color matrix compactness"
    "inclusions inclusions inclusions";
  margin-top: 30px;
}
.inclusions {
  grid-area: inclusions;
}
.color {
  grid-area: color;
}
.matrix {
  grid-area: matrix;
}
.compactness {
  grid-area: compactness;
}
