$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 480px;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

body {
  /* overflow-y: hidden !important; */
}

hr {
  position: relative;
  top: 15px;
  border: none;
  height: 0.1px;
  background: black;
}

button {
  cursor: pointer;
  background-color: #b7410e;
  color: white;
  border: 0.5px solid black;
}

button:disabled {
  background-color: rgba(239, 239, 239, 0.3);
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(118, 118, 118, 0.3);
}

.margin-left {
  margin-left: 15px;
}

.container {
  margin: 40px 200px 150px 330px;
}

.h3-title {
  font-weight: 700;
  font-size: 18px;
  color: #b7410e;
}

h1 {
  color: #b7410e;
}

input {
  font-size: 16px;
}

.ue-input {
  text-indent: 10px;
  width: 400px;
}

.multiSelectContainer,
.multiSelectContainer *,
.multiSelectContainer::after,
.multiSelectContainer::before {
  box-sizing: content-box !important;
}

.multiSelectContainer li:hover {
  background-color: #b7410e !important;
}

.multiSelectContainer {
  width: 190px;
}

.chip {
  background: #b7410e !important;
}

.singleChip {
  background: none !important;
}

.Toastify__toast-container {
  width: 300px;
  text-align: center;
  font-size: 18px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: green;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: red;
}

.others {
  display: flex;
  flex-direction: column;
}

.others-input {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  margin-left: 5px;
  gap: 3px;
  z-index: 999;
}

.Button-module_--primary__1ZLkB {
  background-color: #b7410e !important;
}

.inclusionsMultiselect {
  width: 700px !important;
}

.wordBreak {
  word-break: break-all;
}
