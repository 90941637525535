.SidebarNav {
  background: #15171c;
  width: 260px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  /* caso queira por cima do nav de cima aumentar z-index */
  z-index: 10;
}

.linkWrapper {
  margin-top: 60px;
}

.SidebarWrap {
  width: 100%;
}
