.intervention-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  /* gap: 0px 0px; */
  grid-auto-flow: row;
  grid-template-areas: "intervention";
}

.intervention {
  grid-area: intervention;
}

.material-multiselect {
  min-width: 300px;
}
