.metal-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 60px 60px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description description"
    "description description description description"
    "fragments fragments . ."
    "form form material state"
    "function function typology typology"
    "coordinates x y z";
}

.description {
  grid-area: description;
}

.fragments {
  grid-area: fragments;
}

.material {
  grid-area: material;
}

.form {
  grid-area: form;
}

.state {
  grid-area: state;
}

.function {
  grid-area: function;
}

.typology {
  grid-area: typology;
}

.coordinates {
  grid-area: coordinates;
}

.x {
  grid-area: x;
}

.y {
  grid-area: y;
}

.z {
  grid-area: z;
}
