.lithic-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 60px 60px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "description description description description"
    "description description description description"
    "fragments fragments . ."
    "typology typology material cronology"
    "length length width thickness "
    "coordinates x y z";
}

.description {
  grid-area: description;
}

.fragments {
  grid-area: fragments;
}

.material {
  grid-area: material;
}

.typology {
  grid-area: typology;
}

.cronology {
  grid-area: cronology;
}

.coordinates {
  grid-area: coordinates;
}

.x {
  grid-area: x;
}

.y {
  grid-area: y;
}

.z {
  grid-area: z;
}

.length {
  grid-area: length;
}

.width {
  grid-area: width;
}

.thickness {
  grid-area: thickness;
}

.measures {
  grid-area: measures;
}
