table {
  font-family: "Fira Code";
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}
table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #b7410e;
  color: white;
  text-transform: uppercase;
}

.centre {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  text-align: center;
}

.bottomwhitespace {
  /* padding-bottom: 100pt;
  padding-top: 100pt;
  font-size: 0; */
}

.btn-group button {
  cursor: pointer;
  background-color: white;
  /* margin-top: 35pt; */
  /* border: 0.5px solid black; */
  border: none;
  color: #632ce4;
  padding: 15px 32px;
  /* text-align: center; */
  font-size: 16px;
  /* display: inline-block; */
}

.btn-group i {
  color: #632ce4;
  cursor: pointer;
  font-size: 30px;
  padding: 10px;
}

.btn-group i:hover {
  /* background-color: #632ce4; */
  color: white;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.pagination-container button {
  border-radius: 0px;
  border: 0.5px solid black;
  padding: 5px 10px 5px 10px;
  /* margin-right: 5px; */
  cursor: pointer;
}

.TableButton {
  color: #b7410e;
  margin-left: 15px;
  cursor: pointer;
}

.TableButton:hover {
  color: white;
}
