.show-poll-container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas: "name place";
}

.name {
  grid-area: name;
}

.place {
  grid-area: place;
}

.border {
  border: 1px solid black;
  padding: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
}
.show-poll-container h3 {
  margin-top: auto;
  margin-bottom: auto;
}

.title-container {
  display: flex;
  /* margin-top: 50px; */
}

.title-container button {
  margin: auto 0 auto auto;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  font-size: 16px;
}

.plus-icon {
  margin: auto 0 auto auto;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  font-size: 36px;
  cursor: pointer;
}

.plus-icon:hover {
  color: #b7410e;
  font-size: 40px;
}

.title-container button:hover {
  font-weight: bold;
}

.print-matrix {
  margin: 20px 0 20px 10px !important;
  /* padding: 8px !important; */
  width: 200px !important;
  font-size: 16px;
}
