.constructive-container {
  display: grid;
  grid-template-columns: 320px 300px 300px;
  grid-template-rows: 50px 50px 50px;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "type device material"
    "length width thickness"
    "inclusions inclusions inclusions";
}

.type {
  grid-area: type;
}

.device {
  grid-area: device;
}

.material {
  grid-area: material;
}

.length {
  grid-area: length;
}

.width {
  grid-area: width;
}

.thickness {
  grid-area: thickness;
}

.inclusions {
  grid-area: inclusions;
}

.constructive-container h3 {
  margin-top: auto;
  margin-bottom: auto;
}
