.material-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 60px;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "intervention UE"
    "material .";
}

.intervention {
  grid-area: intervention;
}

.UE {
  grid-area: UE;
}

.material {
  grid-area: material;
}
