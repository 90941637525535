.profile-container {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "username username ."
    "password password .";
}

.username {
  grid-area: username;
}

.password {
  grid-area: password;
}
