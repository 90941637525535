.matrix-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "relation . . ."
    "overlapped overlapped cut cut"
    "cuted cuted fills fills"
    "filledd filledd pull pull"
    "equal equal . .";
  margin-top: 15px;
}

.relation {
  grid-area: relation;
}

.overlapped {
  grid-area: overlapped;
}

.cut {
  grid-area: cut;
}

.cuted {
  grid-area: cuted;
}

.fills {
  grid-area: fills;
}

.filled {
  grid-area: filledd;
}

.pull {
  grid-area: pull;
}

.equal {
  grid-area: equal;
}

.matrix-input {
  min-width: 270px;
}
