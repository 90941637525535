.SidebarLink {
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  list-style: none;
  height: 80px;
  text-decoration: none;
  font-size: 18px;
}

.SidebarLink:hover {
  background: #252831;
  border-left: 4px solid #b7410e;
  cursor: pointer;
}

.SidebarLabel {
  margin-left: 20px;
}

.DropdownLink {
  background: #414757;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
}

.DropdownLink:hover {
  background: #b7410e;
  cursor: pointer;
}

.logoutDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media only screen and (min-height: 912px) {
  .logoutDiv {
    bottom: 50px;
  }
}
