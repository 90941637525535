.view-intervention-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 80px 80px;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "place type classification"
    "acronym latitude longitude";
}

.view-intervention-container h3 {
  margin-top: auto;
  margin-bottom: auto;
}

.classification {
  grid-area: classification;
}

.place {
  grid-area: place;
}

.type {
  grid-area: type;
}

.acronym {
  grid-area: acronym;
}

.latitude {
  grid-area: latitude;
}

.longitude {
  grid-area: longitude;
}

.border {
  border: 1px solid black;
}

.flex {
  display: flex;
  flex-direction: row;
}

.view-intervention-title {
  margin-top: auto;
  margin-bottom: auto;
}

.intervention-values {
  margin-left: 10px;
}

.intervention-checkbox {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 3em;
}

.intervention-checkbox input {
  margin-top: auto;
  margin-bottom: auto;
  transform: scale(1.3);
  margin-left: 10px;
}

.editMultiselect {
  width: 100% !important;
}
