.create-intervention-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 1em 1em;
  grid-auto-flow: row;
  grid-template-areas:
    "place acronym"
    "latitude longitude";
  margin-top: 2em;
}

.place {
  grid-area: place;
}

.acronym {
  grid-area: acronym;
}

.latitude {
  grid-area: latitude;
}

.longitude {
  grid-area: longitude;
}

.intervention-if {
  width: "300px";
}
